/* src/index.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
  color: #333;
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

nav li {
  margin: 0 10px;
}

nav a {
  text-decoration: none;
  color: #333;
}

button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.not_found {
  width: 100%;
  text-align: center;
  margin-top: 20%;
  font-size: 50px;
}

.home {
  text-align: center;
  display: grid;
  margin-top: 20%;
  justify-content: center;
}

.home img {
  width: 300px;
}

.web_app {
  width: 300px;
  height: 120px;
}